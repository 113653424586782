import React from 'react';
import './App.css';

import {
    Link
} from 'react-router-dom';

class PlanetPreview extends React.Component {
    constructor(){
        super();

        this.state = {
            post: {
                title: 'Planetary Data',
                preview: 'Using several different sources, I have created a REST API that contains dynamic data relating to planets in our solar system.',

            }
        };
    }

    render() {
        return (

            <div className='PostPreviewHeaderDiv'>
            {/* <Link to="/PlanetaryData"> */}
            <h2 className='PostPreviewHeader'>{this.state.post.title}</h2>
            {/* </Link> */}
            <p className='PostPreviewText'>{this.state.post.preview} <Link to='/PlanetaryData'>(Read more)</Link></p>

    
    

            </div>

        );
    }
}

export default PlanetPreview;