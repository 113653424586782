import React from 'react';
import styled from 'styled-components';
import Header from '../../Header';
import dutchdikes from '../../photos/dike_history_2013_netherlands.jpeg';
import nasaspinoffs from '../../photos/nasa_impacts.jpeg';
import futuristicmoon from '../../photos/landscap_chatgpt_dec2023.jpeg';
import { Helmet } from 'react-helmet';

const PostContainer = styled.div`
  max-width: 70%;
  margin: auto;
  padding: 20px;
  font-family: 'Helvetica', 'Arial', sans-serif;

  @media (max-width: 900px) {
    max-width: 87%;

  }
`;

const PostTitle = styled.h1`
    color: #333;
    font-size: 1.25em;
    text-align: center;
    margin-bottom: 20px;

    @media (min-width: 800px) {
    font-size: 2em;
}
`;

const PostParagraph = styled.p`
  color: #444;
  font-size: .75em;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: PublicoText, Georgia, Cambria, Times New Roman;

  &:before {
    content: '\t'; //represents tab
    white-space: pre; //preserve whitespace
  }

  @media (min-width: 800px) {
    font-size: 1.2em;
  }
`;

const SubHeading = styled.h3`
  color: #555;
  font-weight: normal;
  margin-top: 5px;
  text-align: left;
  font-weight: bold;
  font-size: .6em;
  font-style: italic;
  margin-bottom: 5px;

  @media (min-width: 800px) {
    font-size: 1em;
  }
`;

const SubHeadingDiv = styled.div`
  width: 100%;
  align-content: right;
  margin-bottom: 3%;
`

const ImageDiv = styled.div`
  max-width: 80%;
  height: auto;
  margin: 0 10%;
`

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;

`;

const ImageDiv2 = styled.div`
  max-width: 100%;
  max-height: 50%;
`

const ImageDescription = styled.div`
  font-family: 'BWHaasGrotesk-55Roman-Web', 'Helvetica', 'Arial', sans-serif;
  text-align: left; // You can adjust alignment as needed
//   padding: 10px 0;    // Adjust padding for better spacing
  font-size: .75em;     // Adjust font size as needed
`;

const BlockDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; // Two equal columns
  gap: 20px; // Space between columns
  width: 100%;
  margin: 20px 0;

  // Responsive adjustments
  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Stack vertically on smaller screens
  }
`;



function DecPostFull() {
  const postTitle = "Climate Optimism and Facilitating Technological Progress";
  const postDescription = "Overcoming doom-and-gloom will be paramount in recruiting our best and brightest to work on the most challenging - and important - problems that we face.";
  const postImageUrl = "/chatgpt_image_dec2023.jpeg";
    return (
        <div>
        <Helmet>
          <title>{postTitle}</title>
          <meta property="og:title" content={postTitle} />
          <meta property="og:description" content={postDescription} />
          <meta property="og:url" content="https://drewhahn.me/Jan2024" />
          <meta property="og:image" content={postImageUrl} />
        </Helmet>
        <Header />
        <PostContainer>
            <PostTitle>
                Climate Optimism and Facilitating Technological Progress
            </PostTitle>

            <SubHeadingDiv>
                <SubHeading>Drew Hahn</SubHeading>
                <SubHeading>2 January 2024</SubHeading>
            </SubHeadingDiv>

            <PostParagraph>
                It is easy to get caught up in the prevailing narrative that as a planet and society, we are headed for collapse. I would like to be one of the few operators in climate-tech to reject this notion. Don’t get me wrong - an unmitigated climate strategy would result in disaster, especially for those most marginalized around the globe. But by focusing on the worst case with no mitigation, rather than the reality of a vast landscape of solutions ahead of us, we risk writing our own perverse prophecy by discouraging our most innovative from tackling our greatest challenges. In my time in the climate-tech landscape, I have come across so many who are incredibly smart, but share a message of hopelessness. For as many intelligent people I have met, I have met even more who are apathetic due to perceived lack of ability to make a difference. 
                {/* Rest of your paragraph text */}
            </PostParagraph>
            <br />
            <br />
            
                <ImageDiv>
                <StyledImage src={dutchdikes} alt='The construction of a Dutch dike to prevent flooding in a town'/>
                <ImageDescription>
                Construction of a new Dutch dike in 2013 to protect the waterfront of a town. (Source: dutchdikes.net)
                </ImageDescription>
                </ImageDiv>

            <br />
            <PostParagraph>
                I would like to address a few of the charges that cause so many to be hopeless and keep even more from joining the cause to make a difference. Rising sea levels will undoubtedly cause migration and shifting of landscape. We have already seen modern adaptions to rising tides. For example, nearly 1/3 of the Netherlands currently resides below sea level. The country is not underwater because of a broad system of dikes, pumps, and sand dunes along the coast. While these are not inexpensive solutions, they are viable in nearly every global metropolitan area that is highly exposed to rising sea levels. With respect to overall increases in global temperatures, as a society we should absolutely aim for keeping net temperature increase from raising over 1.5 degrees Celsius above pre-industrial levels. Unlike what many think, however, the world will not end if we miss this mark marginally. It is that very rhetoric that keeps many from joining forces and trying to change our planet for the better. Beyond this threshold, crisis management is about saving lives rather than facing extinction. If anything, we should encourage more young people to join the cause because for every groundbreaking solution we implement in the near term, we may save thousands of lives in the long-term. 
            </PostParagraph>

            <br />

            <BlockDiv>
                <div>

            <PostParagraph>
                Now that we have addressed some of the realities of the downside risk, I would be remiss if I didn’t address all of the upside that will come with our smartest minds working towards climate solutions. The ramifications have sci-fi-like potential. For example, when considering the broader US economy, each dollar of the US fiscal budget that went towards funding NASA in 2019, yielded a ROIC (return on invested capital) of 3. This means for every dollar budgeted towards NASA, studies have shown a 3-fold return to the American economy. This is just a short-term return; the long term positive externalities are likely even greater. Additionally, this ROIC was previously even larger while space-tech was a more nascent industry. Without the early innovation in space-tech, we would not have had satellite television, GPS, solar panels, digital image processing, cordless tools, vacuums, and countless other consumer-facing technologies. The innovation and application that stemmed from investment into traveling through space has been a force-multiplier for the global economy.
            </PostParagraph>
            </div>
            <div>
                <StyledImage src={nasaspinoffs} alt='A graphic of the different impacts that NASA innovation has had on the world economy' />
                <ImageDescription>
                    Investment into new technology tends to impact a much larger scope than initially targeted. (Source: KQED.org)
                </ImageDescription>
            </div>
            </BlockDiv>
            {/* <BlockDiv>
                <GridImage>
                    <StyledImage src={nasaspinoffs} alt='A graphic of the different impacts that NASA innovation has had on the world economy' />
                    <ImageDescription>
                    Investment into new technology tends to impact a much larger scope than initially targeted. (Source: KQED.org)
                </ImageDescription>
                </GridImage>
                <GridText>
                <PostParagraph>
                                Now that we have addressed some of the realities of the downside risk, I would be remiss if I didn’t address all of the upside that will come with our smartest minds working towards climate solutions. The ramifications have sci-fi-like potential. For example, when considering the broader US economy, each dollar of the US fiscal budget that went towards funding NASA in 2019, yielded a ROIC (return on invested capital) of 3. This means for every dollar budgeted towards NASA, studies have shown a 3-fold return to the American economy. This is just a short-term return; the long term positive externalities are likely even greater. Additionally, this ROIC was previously even larger while space-tech was a more nascent industry. Without the early innovation in space-tech, we would not have had satellite television, GPS, solar panels, digital image processing, cordless tools, vacuums, and countless other consumer-facing technologies. The innovation and application that stemmed from investment into traveling through space has been a force-multiplier for the global economy.
                </PostParagraph>
                </GridText>
            </BlockDiv> */}




            <PostParagraph>
                I say all of this because there are so many similarities in climate-tech. We are in such early days, and yet the long-term applications to new technologies are already apparent. For example, the work in development towards solid-state batteries will one day (hopefully soon) revolutionize the automotive and space transportation industries. Imagine what is possible when cars can drive 1000 miles on a charge, or solar panels can power our spaceship batteries to infinite accelerative capability. The entire capacity of the human race will be permanently changed. Another example comes from looking at carbon removals. Carbon removal gives us an opportunity to terraform our planet. Aside from the broad short-term application of general improvement in our understanding of specific chemical interactions, this provides an insight into how we may go about one day shaping other planets. In solving problems surrounding energy efficiency, there will undoubtedly be breakthroughs in our ability to continue exploration, eventually towards farther reaching parts of our solar system and galaxy. It is for this reason, that we must encourage our brightest minds towards solving our toughest challenges. 
            </PostParagraph>

            <br />
            <br />
            
            <ImageDiv2>
                <StyledImage src={futuristicmoon} alt='A futuristic portrayal of the moon, having been terraformed to support human and plant life' />
            </ImageDiv2>
            <br />
            <PostParagraph>
            At my core, I am a sci-fi junkie and a dreamer. I think that as we grow older, we often feel we must sacrifice the world we dream of for the one that we live in. I think it is imperative to challenge this assumption. In fact, as we get closer to our demise, this should incite us to work feverishly to pursue our dreams in the limited time that we are here. We must take ownership of our future, if not for ourselves and our goals, for the aspirations and imaginations of the world our children may live in. From an economic perspective, technological progress is a multiplier on the production output of any society. More importantly though, our collective aspirations guide the direction of a society as a whole. If we develop the whim and will to drive change through technological growth, we will increasingly shape the world towards a fascinating future. This change in our nature can only the be result of a change in outlook. We need to be optimists. It is no secret that optimism is a much stronger motivational tool than fear. Our best and brightest must be given hope that the entirety of their efforts will not only be rewarded monetarily, but will mean a great deal in a broader sense for the direction of our civilization. If we can achieve this, not only will we mitigate the greatest threats that climate change may present, but we will positively reshape the direction of humanity.
            </PostParagraph>

        </PostContainer>

        <br />
        <br />
        <br />
        </div>
    );
}

export default DecPostFull;
