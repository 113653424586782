import React from 'react';
import './App.css';

import {
    Link
} from 'react-router-dom';



class PostPreview extends React.Component {
    constructor(){
        super();
        this.state = {
            count: 0,
            post: {
                title: 'A New Type of Recommender System for Your Spotify Account',
                preview: 'I am interested in altering the way that we discover new songs. Instead of taking a genre-based approach to recommendations, consider strictly the audial properties of the song itself.'
            }
            
        };

        this.incrementMe = this.incrementMe.bind(this)
       

        
    }

    incrementMe() {
        let newCount = this.state.count+1
        this.setState(state => ({
            count: newCount
            
        }));
    }

    render(){

    return (

        <div className='PostPreviewHeaderDiv'>

        <h2 className='PostPreviewHeader'>{this.state.post.title}</h2>

        <p className='PostPreviewText'>{this.state.post.preview} <Link to='/Post'>(Read more)</Link></p>
            {/* <Route exact path='/Post' element={Post} /> */}

        </div>

    );
    }
};



export default PostPreview;